@import '~bloko/common/styles/_column-variables';
@import '~bloko/common/styles/button-base';
@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/spacing-base';
@import '@hh.ru/magritte-ui/tokens';

@xs-columns-half-size: 25% * @column-width * 2;
@s-columns-half-size: @column-width * 4;
@m-columns-half-size: @column-width * 6;
@l-columns-half-size: @column-width * 8;

.abstract-left-margin(@columns-half-size) {
    left: calc(50% + @columns-half-size + 20px);
}

.scroll-to-top {
    width: @control-height-scale-medium;
    height: @control-height-scale-medium;
    padding-right: @column-padding;

    position: sticky;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 1000;

    opacity: 0;
    transition: opacity 200ms;

    bottom: @column-padding;
    .abstract-left-margin(@xs-columns-half-size);

    @media @screen-gt-xs {
        bottom: @spacing-base * 4;
        .abstract-left-margin(@s-columns-half-size);
    }

    @media @screen-gt-s {
        .abstract-left-margin(@m-columns-half-size);
    }

    @media @screen-gt-m {
        bottom: @spacing-base * 6;
        .abstract-left-margin(@l-columns-half-size);
    }
}

.scroll-to-top-shown {
    opacity: 1;
    transition: opacity 600ms;
}

.scroll-to-top-force-hidden {
    transition: opacity 0ms;
}

.scroll-to-top-button {
    border: @button-border-width solid @color-white;

    width: @control-height-scale-medium;
    height: @control-height-scale-medium;

    border-radius: calc(@control-height-scale-medium / 2);
    box-shadow: 0 12px 24px fade(@color-blue-80, 32%);
    background-color: @color-white;

    &:hover,
    &:focus {
        background: @color-gray-10;
        border-color: @color-gray-10;
    }

    &:active {
        margin-top: 2px;
        background: @color-gray-20;
        border-color: @color-gray-20;
        box-shadow: 0 12px 24px fade(@color-blue-80, 32%);
    }
}

.scroll-to-top-container {
    opacity: 0;
    transition: opacity 200ms;

    display: flex;
    align-items: center;
    justify-content: center;

    &.shown {
        opacity: 1;
        transition: opacity 600ms;
    }

    &.hidden {
        transition: opacity 0ms;
    }
}

.scroll-to-top-right {
    margin-left: auto;
}
