@import '~bloko/common/styles/colors';

.container {
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: opacity 0.2s ease;
    animation: pulse 4s infinite;
}

.line {
    display: flex;
    flex-direction: row;
    gap: 8px;
    height: 18px;
}

.stripe {
    border-radius: 8px;
    background: @color-gray-10;
    width: 100%;
    transition: width 0.2s ease;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    20% {
        opacity: 0.5;
    }

    40% {
        opacity: 1;
    }
}
