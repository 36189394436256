@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/rounded-corner-base';
@import '~bloko/common/styles/spacing-base';

.employer-snippet {
    --dimension-multiplier: 4;

    border-radius: calc(var(--dimension-multiplier) * @rounded-corner-default);
    padding: calc(var(--dimension-multiplier) * @spacing-base);
    border: 1px solid @color-gray-30;

    @media @screen-gt-xs {
        --dimension-multiplier: 6;
    }
}
