@import '~bloko/common/styles/colors';

.widget-gallery {
    position: relative;
    background: @color-white;
    overflow: hidden;
}

.widget-gallery-magritte {
    border-radius: 24px;
    overflow: hidden;
}

.widget-gallery-load-area {
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}
