@import '../../../../bloko/common/styles/media/_screen';
@import '../../../../bloko/common/styles/_column-variables';
@import '../../../../bloko/common/styles/text-base';
@import '../../../../bloko/common/styles/colors';
@import '../../../mixins/hidden-utils';
@import '../../blocks/modal/variables';

@employer-sidebar-inner-offset: 12px;
@employer-sidebar-logo-size: 160px;
@employer-sidebar-logo-size-xs: 90px;
@employer-sidebar-block-top-offset: 15px;
@employer-sidebar-footer-top-offset: 20px;
@employer-sidebar-margin-top: @employer-sidebar-logo-size + @employer-sidebar-inner-offset;

/* stylelint-disable-next-line hhru/less-variable-value-disallowed-list */
@custom-border-color: #dce3eb;

.employer-sidebar-constructor-wrapper {
    margin-top: -@employer-sidebar-margin-top;
    position: relative;

    @media @screen-lt-s {
        margin-top: 0;
    }
}

.employer-sidebar-wrapper {
    border-radius: 24px;
    overflow: hidden;

    @media @screen-gt-s {
        border: 1px solid @custom-border-color;
    }

    @media @screen-lt-m {
        background-color: @color-white;
        margin: 0 -@column-padding;
    }

    @media @screen-lt-s {
        border-bottom: 1px solid @custom-border-color;
        border-radius: 0 0 24px 24px;
    }
}

.employer-sidebar {
    box-sizing: border-box;
    background-color: @color-white;
    padding: @employer-sidebar-inner-offset;
    padding-top: @employer-sidebar-footer-top-offset;
    position: relative;

    @media @screen-lt-m {
        display: flex;
        justify-content: space-between;
        padding: @employer-sidebar-inner-offset * 2;
    }

    @media @screen-lt-s {
        flex-wrap: wrap;
    }
}

.employer-sidebar-interview-url {
    display: block;
    margin-bottom: 10px;
}

.employer-sidebar__logo-container {
    width: @employer-sidebar-logo-size;
    height: @employer-sidebar-logo-size;
    position: relative;

    @media @screen-gt-s {
        padding-left: @employer-sidebar-inner-offset;
        padding-right: @employer-sidebar-inner-offset;
    }

    @media @screen-lt-s {
        order: 2;
        width: @employer-sidebar-logo-size-xs;
        height: @employer-sidebar-logo-size-xs;
    }
}

.employer-sidebar__logo-img-container {
    line-height: @employer-sidebar-logo-size;
    height: 100%;
    text-align: center;
}

.employer-sidebar__logo {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;

    @media @screen-lt-m {
        vertical-align: top;
    }
}

.employer-sidebar-constructor-guide-link {
    text-align: center;
}

.employer-sidebar-button {
    margin-bottom: 10px;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.employer-sidebar-header {
    .hidden-gt-s();

    width: 100%;
    box-sizing: border-box;

    @media @screen-lt-m {
        padding: @employer-sidebar-inner-offset * 2;
        padding-bottom: 0;
    }
}

.employer-sidebar-header_hide-title-on-xs {
    .hidden-xs();
}

.employer-sidebar-logo-load {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.25s ease;
    padding: 4px;
    text-align: center;
    box-sizing: border-box;
}

.employer-sidebar-logo-load_no-image {
    padding-top: 44px;
    border: 1px solid @color-gray-20;

    @media @screen-lt-s {
        padding-top: 10px;
    }
}

.employer-sidebar-logo-load_drop,
.employer-sidebar-logo-load:hover {
    background-color: fade(@color-special-absolute-black, 20%);
}

.employer-sidebar-logo-load__icon {
    margin: 0;
}

.employer-sidebar-logo-load__icon_container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: fade(@color-special-absolute-black, 20%);
}

.employer-sidebar-logo-load__add-link {
    margin-top: 9px;
    position: relative;
}

.employer-sidebar-logo-load__description {
    .bloko-text-tertiary();

    margin: 11px 15px 0;

    @media @screen-lt-s {
        display: none;
    }
}

.employer-sidebar-logo-load__description_inverted {
    color: @color-gray-20;
}

.employer-sidebar-logo-load__button {
    margin-bottom: 4px;
    text-align: right;
    display: none;

    .employer-sidebar-logo-load:hover & {
        display: block;
    }
}

.employer-sidebar-content {
    @media @screen-gt-s {
        padding-left: @employer-sidebar-inner-offset;
        padding-right: @employer-sidebar-inner-offset;
    }

    @media @screen-lt-s {
        order: 1;
    }

    @media @screen-lt-m {
        max-width: 30%;
        margin-top: -@employer-sidebar-block-top-offset;
    }

    @media @screen-lt-s {
        max-width: calc(100% - @employer-sidebar-logo-size-xs);
    }
}

.employer-sidebar-content_without-logo {
    margin-top: -@employer-sidebar-block-top-offset;
}

.employer-sidebar-block {
    margin-top: @employer-sidebar-block-top-offset;
}

.employer-sidebar__footer {
    @media @screen-s {
        width: 200px;
        margin-top: 0;
    }

    @media @screen-lt-s {
        order: 3;
        width: 100%;
    }
}

.employer-sidebar-open-employer {
    background: @color-white;
    padding: 4px;
    border-radius: 50%;
}
