@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/_abstract-modal';
@import '~bloko/common/styles/text-base';
@import '~bloko/common/styles/spacing-base';
@import '../../styles/globals/blocks/modal/variables';

:root {
    --modal-content-height: 500px;
    --modal-sidebar-and-map-height: 400px;
}

.root {
    width: @modal-max-size;
    height: 664px;
    max-height: 100vh;

    @media @screen-lt-s {
        height: calc(100% + @modal-padding-xs * 2);
        width: calc(100% + @modal-padding-xs * 2);
        margin: -@modal-padding-xs;
    }
}

.search {
    display: flex;
    gap: 5px;

    margin-top: 20px;
    margin-bottom: 30px;

    @media @screen-lt-s {
        order: 2;
        margin: 0;
        padding: @modal-padding-xs;
        border: 1px solid @color-gray-30;
        border-left: 0;
        border-right: 0;
    }
}

.address-title {
    padding: 0;

    @media @screen-lt-s {
        padding: @modal-padding-xs @modal-padding-xs 0;
    }
}

.loading-wrapper {
    text-align: center;
}

.balloon {
    padding: 15px;
}

.address-metro-item {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-right: -5px;
}

.address-modal-content {
    display: flex;
    flex-direction: column;
}

.address-modal-content_full-screen {
    height: var(--modal-content-height);
}

.address-modal-search {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
}

.address-modal-sidebar-and-map {
    display: flex;
    gap: 24px;
    flex-grow: 1;
    max-height: var(--modal-sidebar-and-map-height);
}

.address-modal-map-wrapper {
    width: 100%;
    height: 100%;
}

.address-modal-map-wrapper_mobile {
    height: 180px;
}

.address-modal-sidebar {
    height: 100%;
    flex-shrink: 0;
    display: none;
    width: 232px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 4px;
    margin-right: -4px;
}

.address-modal-sidebar_shown {
    display: block;
}
