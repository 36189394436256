.widget-height-by-ratio {
    position: relative;
}

.widget-height-by-ratio__content {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
}
