@import '@hh.ru/magritte-ui/base-variables';
@import '@hh.ru/magritte-ui/breakpoints';

.suggest-form-wrapper {
    display: flex;
    gap: @magritte-static-space-200;

    .screen-gt-s({
        gap: @magritte-static-space-600;
     });
}

.suggest-form {
    flex-grow: 1;
}
