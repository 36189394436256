.widget-video .widget-video__frame {
    width: 100%;
    height: 100%;
}

.widget-video-magritte {
    border-radius: 24px;
    overflow: hidden;
}

.widget-video__frame_overlap {
    pointer-events: none;
}
