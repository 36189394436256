.filters-count-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filters-count {
    display: flex;
    align-items: center;
}
