@import '~bloko/common/styles/media/_screen';

.content {
    display: flex;
    column-gap: 40px;
}

.content__item {
    @media @screen-gt-xs {
        max-width: 256px;
    }
}
