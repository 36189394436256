@import '~bloko/common/styles/_clearfix';
@import '~bloko/common/styles/colors';
@import '@hh.ru/magritte-ui/tokens';

.widget-address-view {
    .clearfix();

    background-color: @color-gray-10;
}

.widget-address-view-magritte {
    border: 1px solid @magritte-color-background-tertiary;
    background-color: @magritte-color-base-white;
    border-radius: 24px;
    overflow: hidden;
}
