@import '~bloko/common/styles/_abstract-control';
@import '~bloko/common/styles/colors';

.widget-controls {
    padding: 5px;
    background-color: @color-gray-20;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: @control-height-scale-medium;
}

.widget-controls__button-wrapper {
    margin-left: 5px;
    display: inline-block;
}

.widget-controls__name {
    margin-left: 10px;
    vertical-align: middle;
}

.widget-controls__move-wrapper {
    cursor: move;
    display: inline-block;
}

.widget-controls__move-overlay {
    pointer-events: none;
}
