// Нужен белый цвет из блоко по аналогии с src/pages/PromotionPayForContact/components/FeaturesSection/desktop.less
@import '~bloko/common/styles/colors';
@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';

.content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
}

.description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    .screen-gt-xs({
        flex-direction: row;
        align-items: normal;
    });
}

.employer-tabs-wrapper {
    position: sticky;
    background: fade(@color-white, 80%);
    backdrop-filter: blur(24px);
    top: 0;

    border-bottom: 1px solid @magritte-color-background-tertiary;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
}
