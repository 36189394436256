@import '~bloko/common/styles/colors';

@button-height: 38px;
@button-width: 128px;

@button-height-small: 28px;
@button-width-small: 52px;

@button-background-size: 22px;
@button-background-padding-left: 15px;
@button-background-padding-right: 10px;

@button-background-size-small: 20px;

.button {
    height: @button-height;
    width: @button-width;
    box-sizing: border-box;

    border-radius: calc(@button-height / 2);
    border: 1px solid @color-gray-30;

    padding-left: calc(@button-background-padding-left + @button-background-size + @button-background-padding-right);

    line-height: 1.4;
    text-align: left;
    overflow: hidden;

    background-color: @color-white;
    background-repeat: no-repeat;
    background-size: @button-background-size;
    background-position-x: @button-background-padding-left;
    background-position-y: 50%;

    &:focus {
        box-shadow: 0 1px @color-gray-20;
    }

    &:active {
        box-shadow: inset 0 1px 1px @color-gray-50;
        padding-top: 2px;
        background-position-y: calc(50% + 1px);
    }
}

.button_small {
    height: @button-height-small;
    width: @button-width-small;

    border-radius: calc(@button-height-small / 2);

    padding: 0;

    background-size: @button-background-size-small;
    background-position-x: center;
}

.button_viber {
    background-image: url('images/button-viber.svg');
}

.button_vk {
    background-image: url('images/button-vk.svg');
}

.button_telegram {
    background-image: url('images/button-telegram.svg');
}
