.form {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 15px;
}

.email {
    width: 260px;
}
