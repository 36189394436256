@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/base-variables';

.employer-chameleon-header {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .screen-gt-xs({
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    });
}
