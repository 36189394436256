@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';
@import '../../../styles/globals/blocks/employer-sidebar/employer-sidebar';

@employer-constructor-header-height: 300px;
@employer-constructor-header-height-xs: 180px;

.employer-constructor-header-edit {
    transition: all 0.25s ease;
}

.employer-constructor-header-edit_without-image:hover,
.employer-constructor-header-edit_drop {
    opacity: 0.5;
}

.employer-constructor-header {
    height: @employer-constructor-header-height;
    background-size: cover;
    background-position: center;
    background-color: @color-gray-50;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to bottom, fade(@color-white, 0) 50%, fade(@color-special-absolute-black, 70%));
    }

    @media @screen-lt-s {
        height: @employer-constructor-header-height-xs;
    }
}

.employer-constructor-header_without-image {
    &::before {
        content: none;
    }
}

.employer-constructor-header__title {
    position: relative;
    height: @employer-constructor-header-height;
    display: flex;
    align-items: flex-end;

    .hidden-s();

    @media @screen-lt-s {
        height: @employer-constructor-header-height-xs;
    }

    @media @screen-s {
        height: @employer-constructor-header-height - @employer-sidebar-margin-top;
    }
}

.employer-constructor-header__title-wrapper {
    display: flex;
    flex-direction: column;
}

.employer-constructor-header__controls {
    position: absolute;
    right: 20px;
    top: 20px;
}

.employer-constructor-header__button-wrapper {
    margin-left: 5px;
    display: inline-block;
}

.employer-constructor-header__promo-upload-button {
    @size: 68px;

    position: absolute;
    width: @size;
    height: @size;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @color-gray-40;
    left: calc(50% - @size / 2);
    top: calc(50% - @size / 2);
    cursor: pointer;
    transition: background-color 0.25s ease;

    &:hover {
        background-color: @color-gray-30;
    }

    @media @screen-s {
        // Вычисляем середину от свободного пространства sidebar
        top: calc((100% - @employer-sidebar-margin-top) / 2 - @size / 2);
    }
}

.employer-constructor-header__promo-upload-icon {
    opacity: 0.5;
    transition: opacity 0.25s ease;
}

.employer-constructor-header__promo-upload-button:hover .employer-constructor-header__promo-upload-icon {
    opacity: 1;
}
