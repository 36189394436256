@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.wrapper {
    border-top: 1px solid @color-gray-30;
}

.wrapper_redesigned {
    border-top: 0;

    @media @screen-gt-xs {
        border-top: 1px solid @color-gray-20;
    }
}

.title-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.title:extend(.title-row) {
    align-items: baseline;
}
