@import '~bloko/common/styles/_column-variables';
@import '~bloko/common/styles/colors';

.add-widget-wrapper {
    margin-bottom: 100px;
    position: relative;
}

.add-widget-column-wrapper {
    margin: 0 -@column-padding;
}

.add-widget {
    text-align: center;
    padding: 15px;
    cursor: pointer;
}

.add-widget__plus {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    border-radius: 50%;
    transition: all 0.25s ease;
    width: 20px;
    height: 20px;
}

.add-widget:hover .add-widget__plus {
    background-color: @color-blue-20;
}

.add-widget-content {
    display: flex;
    justify-content: space-between;
    align-items: middle;
    padding: 20px;
}

.add-widget-type {
    flex: 1;
    text-align: center;
    cursor: pointer;
}
