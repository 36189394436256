.tip {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
}

.tip-alternative-position {
    left: 0;
}
