.widget-image {
    line-height: 0;
}

.widget-image-magritte {
    border-radius: 24px;
    overflow: hidden;
}

.widget-image__image {
    width: 100%;
}

.widget-image-drop-container {
    transition: all 0.25s ease;
}

.widget-image-drop-container_drop {
    opacity: 0.5;
}
