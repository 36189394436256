.widget-gallery-slides-item {
    width: 100%;
    height: 100%;
    user-select: none;
}

.widget-gallery-switcher-container {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
}
