@import '@hh.ru/magritte-ui/breakpoints';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .screen-gt-s({
        padding: 32px;
    });
}
