@import '~bloko/common/styles/colors';

.widget-gallery-item {
    display: flex;
    padding: 10px 0;
    height: 70px;
    overflow: hidden;
}

.widget-gallery-item__number {
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: @color-gray-80;
}

.widget-gallery-item__image {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    border: 1px solid transparent;
    cursor: grab;
    position: relative;

    &:hover {
        border-color: @color-blue-60;
    }
}

.widget-gallery-item__actions {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    align-items: center;
    visibility: hidden;
}

.widget-gallery-item__button {
    border: 0;
    margin: 0;
    outline: none;
    background: transparent;
}

.widget-gallery-item:hover .widget-gallery-item__actions {
    visibility: visible;
}
