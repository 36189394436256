@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/text-base';

.price-branding-region-switcher {
    margin-top: 5px;
}

.price-branding-region-switcher__link {
    float: right;
    color: @color-blue-50;
    cursor: pointer;
    border-bottom: 1px dashed @color-blue-50;
    margin-top: 3px;
}

.price-branding-region-switcher__list {
    .bloko-text-strong();

    line-height: 23px;
}

.price-branding-region-switcher__list_slim {
    font-weight: normal;
}
