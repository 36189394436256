@import '@hh.ru/magritte-ui/tokens';

.list-item {
    list-style-type: none;
    margin-bottom: @magritte-static-space-300;
}

.list-item:last-child {
    margin-bottom: 0;
}
