@import '@hh.ru/magritte-ui/breakpoints';

.logos-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.roud-logo {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-size: cover;

    .screen-gt-s({
        height: 96px;
        width: 96px;
    });
}

.hh-logo {
    background-image: url('assets/hh-logo.png');
    .roud-logo();
}

.zarplata-logo {
    background-image: url('assets/zarplata-logo.svg');
    .roud-logo();
}

.rabota-by-logo {
    background-image: url('assets/rabota-by-logo.svg');
    .roud-logo();
}

.setka-logo {
    background-image: url('assets/setka-logo.svg');
    .roud-logo();
}

.setka-logo-transparent {
    background-image: url('assets/setka-logo-transparent.png');
    .roud-logo();
}

.skillaz-logo {
    background-image: url('assets/skillaz-logo.svg');
    .roud-logo();
}

.skillaz-alt-logo {
    background-image: url('assets/skillaz-alt-logo.svg');
    .roud-logo();
}
