@import '~bloko/common/styles/colors';

.base-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;

    background: fade(@color-special-absolute-black, 50%);
}

.container {
    height: 100%;
    .base-container();
}

.modal-container {
    min-height: 340px;
    max-height: 340px;
    .base-container();
}
