@widget-image-load-area-icon-size: 70px;

.widget-edit-description {
    padding: 50px;
}

.widget-edit-description__text {
    width: calc(100% - @widget-image-load-area-icon-size);
    display: inline-block;
    padding-right: 30px;
    box-sizing: border-box;
}

.widget-edit-description__icon {
    width: @widget-image-load-area-icon-size;
    height: @widget-image-load-area-icon-size;
    display: inline-block;
    vertical-align: top;
}

.widget-edit-description__icon_image::before {
    content: url('widget-edit-description-image.svg');
}

.widget-edit-description__icon_video::before {
    content: url('widget-edit-description-video.svg');
}
