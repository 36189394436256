@import '~bloko/common/styles/_abstract-control';
@import '~bloko/common/styles/colors';

.widget-gallery-item__touch-drag {
    position: absolute;
    top: 0;
    right: 0;
}

.widget-gallery-sidebar {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 230px;
    background: @color-white;
}

.widget-gallery-sidebar__content {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    box-sizing: border-box;
    padding-bottom: @control-height-scale-medium;
}

.widget-gallery-item-drop-line {
    @height: 2px;

    height: @height;
    margin-top: -@height / 2;
    margin-bottom: -@height / 2;
    background: @color-blue-60;
}

.widget-gallery-item_stub {
    outline: 1px dashed @color-gray-20;
    background-color: @color-gray-10;
}

.widget-gallery-sidebar__bottom-stick {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: @color-white;
    height: @control-height-scale-medium;
}
