@import '@hh.ru/magritte-ui/breakpoints';

.root {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
    flex-wrap: wrap;

    .screen-gt-xs({
        flex-direction: row;
        align-items: center;
    });
}

.title {
    display: flex;
    align-items: baseline;

    gap: 8px;
}

.title-not-clamped {
    align-items: center;
}

.icons {
    display: flex;
    align-items: center;
    gap: 8px;
}

.collapse-icon {
    flex-shrink: 0;
    cursor: pointer;

    font-size: 0;
}
