@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

@employer-sidebar-inner-offset: 12px;
@employer-sidebar-logo-size-xs: 90px;

.want-work-here-text {
    color: @color-green-60;
}

.logo-edit-container {
    position: relative;
}

.logo-container {
    position: relative;
    display: flex;
    justify-content: center;

    @media @screen-gt-s {
        padding-left: @employer-sidebar-inner-offset;
        padding-right: @employer-sidebar-inner-offset;
    }

    @media @screen-lt-s {
        order: 2;
        width: @employer-sidebar-logo-size-xs;
        height: @employer-sidebar-logo-size-xs;
    }
}
