@import '~bloko/common/styles/colors';

.add-widget-type {
    flex: 1;
    text-align: center;
    cursor: pointer;
}

.add-widget-type_active:hover {
    color: @color-blue-60;
}

.add-widget-type_limit {
    opacity: 0.3;
    cursor: default;
}

.add-widget-type__image {
    margin: 10px auto;
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('./add-widget-button-widgets-icons.svg');
}

.add-widget-type__image_address {
    background-position-x: 0;

    .add-widget-type_active:hover & {
        background-position-x: -26px;
    }
}

.add-widget-type__image_gallery {
    background-position-x: -52px;

    .add-widget-type_active:hover & {
        background-position-x: -78px;
    }
}

.add-widget-type__image_picture {
    background-position-x: -104px;

    .add-widget-type_active:hover & {
        background-position-x: -130px;
    }
}

.add-widget-type__image_separator {
    background-position-x: -156px;

    .add-widget-type_active:hover & {
        background-position-x: -182px;
    }
}

.add-widget-type__image_text {
    background-position-x: -208px;

    .add-widget-type_active:hover & {
        background-position-x: -234px;
    }
}

.add-widget-type__image_video {
    background-position-x: -260px;

    .add-widget-type_active:hover & {
        background-position-x: -286px;
    }
}
