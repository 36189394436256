@import '~bloko/common/styles/media/_screen';

@width: 550px;

.crop-image-popup__footer {
    display: flex;
    justify-content: flex-end;
}

.crop-image-popup__content {
    width: @width;
    overflow-y: auto;

    @media @screen-lt-s {
        width: 100%;
    }
}

.crop-image-popup__error {
    width: @width;

    @media @screen-lt-s {
        width: 100%;
    }
}
