@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/_layers';
@import '~bloko/common/styles/colors';

@employer-constructor-controls-padding: 20px;

.employer-constructor-controls {
    background-color: @color-white;
    box-shadow: 0 0 7px 0 fade(@color-special-absolute-black, 10%);
    transition: box-shadow 0.3s ease;
    padding: @employer-constructor-controls-padding;
    box-sizing: border-box;
    z-index: @content-cover;
    position: sticky;
    bottom: 0;

    @media @screen-lt-s {
        padding: @employer-constructor-controls-padding 0;
    }
}

.employer-constructor-controls_sticked {
    box-shadow: none;
}

.employer-constructor-controls__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.employer-constructor-controls__content-edit-default {
    flex-direction: column;

    @media @screen-gt-s {
        flex-direction: row;
    }
}

.employer-constructor-controls__content-edit-zarplata {
    flex-direction: column;

    @media @screen-gt-s {
        flex-direction: row-reverse;
    }
}

.employer-constructor-controls__content-left {
    display: flex;
}

.employer-constructor-controls-buy-form {
    display: flex;
    align-items: center;

    @media @screen-lt-m {
        margin-bottom: 15px;
    }
}

.employer-constructor-controls__region {
    display: none;

    @media @screen-gt-xs {
        display: block;
        max-width: none;
        width: 100%;
    }

    @media @screen-gt-s {
        max-width: 350px;
    }
}

.employer-constructor-controls__hide {
    display: none;
}
