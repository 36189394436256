@import '@hh.ru/magritte-ui/tokens';

.logo-edit-container {
    position: relative;
}

.logo-edit-input {
    display: none;
}

.avatar-mask {
    height: 96px;
    width: 96px;
    border-radius: 50%;
    transition: all 0.25s ease;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        display: none;
    }
}

.avatar-mask:hover {
    background-color: @magritte-color-base-black;
    opacity: 0.6;

    svg {
        display: block;
    }
}
