@import '~bloko/common/styles/colors';

@widgets-offset-top: 30px;

.widget-base() {
    border: 1px dashed @color-gray-20;
    margin-top: @widgets-offset-top;
}

.employer-constructor-widget {
    .widget-base();

    transition: opacity 0.25s ease;
    background-color: @color-white;
}

.employer-constructor-widget-magritte {
    margin-top: 40px;
}

.employer-constructor-widget_webview {
    margin-top: 20px;
}

.employer-constructor-widget_webview:first-child {
    margin-top: 0;
}

.employer-constructor-widget_view-mode {
    border: 0;
}

.employer-constructor-widget_dragged {
    margin: 0;
    box-shadow: 0 10px 20px fade(@color-special-absolute-black, 10%);
    border: 1px solid @color-blue-60;
    opacity: 0.7;
}

.employer-constructor-widgets-drop-line {
    @height: 4px;

    height: @height;
    border-radius: 2px;
    margin-top: @widgets-offset-top / 2 - @height / 2;
    margin-bottom: -@widgets-offset-top / 2 - @height / 2;
    background: @color-blue-60;
}

.employer-constructor-widgets-drag-element-stub {
    .widget-base();

    background-color: @color-gray-10;
}

.employer-constructor-widgets-with-radius {
    border-radius: 24px;
    overflow: hidden;
}
