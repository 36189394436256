@import '@hh.ru/magritte-ui/breakpoints';

.filters-form-order-by {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    .screen-gt-s({
        flex-direction: row;
    });
}

.filters-form-dummy {
    flex-grow: 1;
}
