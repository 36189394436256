@import '~bloko/common/styles/colors';

@circle-point-size: 12px;
@corner-point-size: 26px;
@point-size-centering-position: calc(-@circle-point-size / 2);

.circle-point {
    cursor: pointer;
    width: @circle-point-size;
    height: @circle-point-size;
    border-radius: 50%;
    background-color: @color-white;
    position: absolute;
}

.circle-point-left-top {
    left: @point-size-centering-position;
    top: @point-size-centering-position;
}

.circle-point-right-top {
    right: @point-size-centering-position;
    top: @point-size-centering-position;
}

.circle-point-right-bottom {
    right: @point-size-centering-position;
    bottom: @point-size-centering-position;
}

.circle-point-left-bottom {
    left: @point-size-centering-position;
    bottom: @point-size-centering-position;
}

.corner-point {
    cursor: pointer;
    width: @corner-point-size;
    height: @corner-point-size;
    position: absolute;
}

.corner-point-left-top {
    border-left: 2px solid @color-white;
    border-top: 2px solid @color-white;
    left: 0;
    top: 0;
}

.corner-point-right-top {
    border-right: 2px solid @color-white;
    border-top: 2px solid @color-white;
    right: 0;
    top: 0;
}

.corner-point-right-bottom {
    border-right: 2px solid @color-white;
    border-bottom: 2px solid @color-white;
    right: 0;
    bottom: 0;
}

.corner-point-left-bottom {
    border-left: 2px solid @color-white;
    border-bottom: 2px solid @color-white;
    left: 0;
    bottom: 0;
}
