@import '@hh.ru/magritte-ui/tokens';

@padding-for-scroll: 24px;

.container {
    position: relative;
}

.scrolling {
    max-height: 276px;
    overflow: auto;
    padding: 0 @padding-for-scroll 0 @magritte-semantic-border-width-tab-focused;
    margin-left: calc(-1 * @magritte-semantic-border-width-tab-focused);

    &::after {
        content: '';
        position: absolute;
        pointer-events: none;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 42px;

        // TODO: добавить токен, как добавится в ДС
        /* stylelint-disable-next-line declaration-property-value-disallowed-list */
        background: linear-gradient(to bottom, #fff0 0%, @magritte-color-base-white 100%);
    }
}
