@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/_layers';

@drag-zone-offset: 3px;

.image-crop {
    position: relative;
    overflow: hidden;
    user-select: none;
    margin: auto;
}

.image-crop-image {
    display: block;
    max-width: 100%;
    max-height: 100%;
}

.image-crop-area {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-color: fade(@color-special-absolute-black, 50%);
    border-style: solid;
    touch-action: none;
}

.image-crop-area-inner {
    position: absolute;
    width: 100%;
    height: 100%;
}

.image-crop-area-drag-zone {
    cursor: move;
    position: absolute;
    left: @drag-zone-offset;
    right: @drag-zone-offset;
    bottom: @drag-zone-offset;
    top: @drag-zone-offset;
}

.image-crop-area-mobile-frame {
    border-radius: 8px;
    border: 2px dashed @color-white;
    opacity: 0.6;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}

.circle-mask {
    // stylelint-disable-next-line
    mask-image: radial-gradient(circle at center, transparent 70%, gray 50%);
    background: fade(@color-special-absolute-black, 50%);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}
