@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/_abstract-control';
@import '~bloko/common/styles/colors';

.widget-gallery-switcher {
    .bloko-text-medium();

    overflow: hidden;
    height: 32px;
    border-radius: 100px;
    align-items: center;
    display: flex;
    margin-bottom: 6px;
    pointer-events: auto;
    user-select: none;

    background: @color-gray-50;
    color: @color-white;

    @media @screen-gt-s {
        margin-bottom: 10px;
        height: 40px;
    }
}

.widget-gallery-switcher-button {
    padding: 0 12px;
    height: 100%;
    background: none;
    border: 0;
    outline: none;
    box-shadow: none;
    display: flex;
    align-items: center;
}
